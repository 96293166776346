import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { useForm } from '../hooks/useForm';
import { settings } from '../config.js';

const OptOutForm = ({ user, setPreferences, setIsLoading }) => {

	const [ schoolOptions, setSchoolOptions ] = useState( [] );
	const [ validationError, setValidationError ] = useState( false );

	useEffect( () => {
		( async () => {
			try {
				const response = await axios({
					method: 'get',
					baseURL: settings.apiUrl,
					url: '/schools',
					headers: {
						api_key: 'TEtBUElBZG1pbjomWGhoWiQqQGhnNFEhNyM='
					}
				});

				const options = response.data.map( ( school, i ) => {
					return {
						key: i + 1,
						text: school.description,
						value: school.id
					};
				});

				setSchoolOptions( options );
			} catch( err ) {
				console.log( err );
			}
		} )();
	}, [] );

	const handlePreferences = () => {
		const preferences = Object.keys( formData ).reduce( ( acc, field ) => {
			if ( field.startsWith( 'preference_' ) ) {
			 return [ ...acc, field ];
			}

			return acc;
		}, [] );

		let preferenceString = [];

		for ( let i = 0; i < preferences.length; i++ ) {
			if ( formData[ preferences[ i ] ]  ) {
				preferenceString = [ ...preferenceString, i + 1 ];
			}
		}

		return preferenceString.join( ', ' );
	}

	const handleAllPreferences = ( e, { checked } ) => {
		const preferences = Object.keys( formData ).reduce( ( acc, field ) => {
			if ( field.startsWith( 'preference_' ) ) {
			 return [ ...acc, field ];
			}

			return acc;
		}, [] );

		updateField({ target: { name: 'allPreferences', value: checked } })

		for ( let preference of preferences ) {
			updateField({ target: { name: preference, value: checked } })
		}
	}

	const { formData, updateField } = useForm({
		firstName: '',
		lastName: '',
		maidenName: '',
		dob: '',
		school: 0,
		allPreferences: false,
		preference_Name: false,
		preference_Address: false,
		preference_Phone: false,
		preference_Email: false,
		preference_DOB: false,
		preference_DOA: false,
		preference_Institution: false,
		preference_Status: false,
		preference_Standing: false,
		preference_Degrees: false
	});

	const handleSubmit = async () => {
		const preferences = handlePreferences();
		if ( !formData.firstName || !formData.lastName || !formData.dob || !formData.school || !preferences.length ) {
			setValidationError( true );
			return;
		}
		setIsLoading( 'Submitting Form...' );
		const data = {
			...user,
			firstName: formData.firstName,
			lastName: formData.lastName,
			maidenName: formData.maidenName,
			dob: formData.dob,
			schoolID: formData.school,
			options: preferences
		}

		try {
			await axios({
				method: 'post',
				baseURL: settings.apiUrl,
				url: '/record',
				data,
				headers: {
					api_key: 'TEtBUElBZG1pbjomWGhoWiQqQGhnNFEhNyM='
				}
			});

			setPreferences( preferences );
			setIsLoading( '' );
		} catch ( err ) {
			console.log( err );
			setIsLoading( '' );
		}
	}

	return (
		<>
			<div className="form-header" style={{ margin: '2rem 0' }} >
				<p>To opt-out, please complete the required fields and review the information below.</p>
			</div>

			<Form
				style={{ maxWidth: '500px', padding: '1rem 0', marginBottom: '2rem', boxSizing: 'border-box' }}
			>
				<Form.Input
					required
					name="firstName"
					label="First Name"
					value={ formData.firstName }
					onChange={ updateField }
				/>
				<Form.Input
					required
					name="lastName"
					label="Last Name"
					value={ formData.lastName }
					onChange={ updateField }
				/>
				<Form.Input
					name="maidenName"
					label="Maiden Name"
					value={ formData.maidenName }
					onChange={ updateField }
					autoComplete="fake"
				/>
				<Form.Field required>
					<label>Date of Birth ( MM/DD/YYYY )</label>
					<DatePicker
						isClearable
						selected={ formData.dob }
						customInput={
							<MaskedInput
								mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
								keepCharPositions= {true}
								guide = {true}
							/>
						}
						onChange={ e => updateField({ target: { name: "dob", value: e } }) }
					/>
				</Form.Field>
				<Form.Select
					required
					name="school"
					label="School You Plan To Attend"
					search
					value={ formData.school || 0 }
					options={
						[
							{ id: 0, text: 'Please select an option', value: 0 },
							...schoolOptions
						]
					}
					onChange={ ( e, { name, value } ) => updateField({ target: { name, value } } ) }
				/>
				<Form.Field required>
					<label style={{ marginBottom: '1rem' }}>Opt-Out Preferences:</label>
					<Form.Checkbox
						name="allPreferences"
						label="I wish to opt-out of all directory information listed below."
						checked={ formData.allPreferences }
						onChange={ handleAllPreferences }
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_Name"
						label="Name"
						checked={ formData.preference_Name }
						onChange={ ( e, { name, checked } ) => {
							updateField({ target: { name, value: checked } })
							updateField({ target: { name: 'allPreferences', value: false } })
						}}
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_Address"
						label="Permanent or home address"
						inline
						checked={ formData.preference_Address }
						onChange={ ( e, { name, checked } ) => {
							updateField({ target: { name, value: checked } })
							updateField({ target: { name: 'allPreferences', value: false } })
						}}
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_Phone"
						label="Telephone numbers"
						inline
						checked={ formData.preference_Phone }
						onChange={ ( e, { name, checked } ) => { updateField({ target: { name, value: checked } }) } }
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_Email"
						label="E-mail address"
						inline
						checked={ formData.preference_Email }
						onChange={ ( e, { name, checked } ) => { updateField({ target: { name, value: checked } }) } }
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_DOB"
						label="Date of birth"
						inline
						checked={ formData.preference_DOB }
						onChange={ ( e, { name, checked } ) => { updateField({ target: { name, value: checked } }) } }
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_DOA"
						label="Dates of attendance"
						inline
						checked={ formData.preference_DOA }
						onChange={ ( e, { name, checked } ) => { updateField({ target: { name, value: checked } }) } }
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_Institution"
						label="Most recently attended institution"
						inline
						checked={ formData.preference_Institution }
						onChange={ ( e, { name, checked } ) => { updateField({ target: { name, value: checked } }) } }
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_Status"
						label="Enrollment status (e.g. full-time, part-time)"
						inline
						checked={ formData.preference_Status }
						onChange={ ( e, { name, checked } ) => { updateField({ target: { name, value: checked } }) } }
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_Standing"
						label="Class Standing"
						inline
						checked={ formData.preference_Standing }
						onChange={ ( e, { name, checked } ) => { updateField({ target: { name, value: checked } }) } }
						tabindex="0"
					/>
					<Form.Checkbox
						name="preference_Degrees"
						label="Degree(s) recieved"
						inline
						checked={ formData.preference_Degrees }
						onChange={ ( e, { name, checked } ) => { updateField({ target: { name, value: checked } }) } }
						tabindex="0"
					/>
				</Form.Field>
			</Form>

			<h4>NOTICE OF DIRECTORY INFORMATION OPT-OUT</h4>
			<p>In accordance with the Federal Educational Rights and Privacy Act of 1974 (FERPA), as amended, a student’s education records are maintained as confidential and, except for a limited number of special circumstances listed in that law, will not be released to a third party without the student’s prior written consent. The law, however, does allow schools to release student “directory information” without obtaining the prior consent of the student. If you do not want the release of certain types of directory information without your prior consent, you may choose to “opt-out” of this FERPA exception by signing the form below. Directory information of a student who has opted-out from the release of directory information, in accordance with this policy/procedure for opting out, will remain flagged until the student requests that the flag be removed by completing and submitting a revocation of the opt-out to the program.</p>

			<h4>INSTRUCTIONS TO THE COMPLETE 2 COMPETE PROGRAM</h4>
			<p>I request the withholding of the following personally identifiable information identified as Directory Information under FERPA. I understand that upon submission of this form, the information checked cannot be released to third parties without my written consent or unless the program is required by law or permitted under FERPA to release such information without my prior written consent; and that the checked directory information will not otherwise be released from the time the program receives my form until my optout request is rescinded. I understand that I may not opt-out of use of my student ID number because it is necessary identifying information for the program. I further understand that if directory information is released prior to the program receiving my optout request, the program may not be able to stop the disclosure of my directory information. I understand that I may request and change how my directory information is used by contacting the program.</p>

			{ validationError
				? <p style={{ color: 'red' }}>Please complete all required (*) fields and select at least one of the opt-out preferences.</p>
				: null
			}

			<Button
				className="btn"
				style={{ boxShadow: '0', marginTop: '2rem' }}
				size="huge"
				content="Submit"
				onClick={ handleSubmit }
			/>
		</>
	);
};

export default OptOutForm;