import React, { useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react'

import './App.css';

import AppHeader from './components/AppHeader';
import SignInForm from './components/SignInForm';
import OptOutForm from './components/OptOutForm';
import SubmissionConfirmation from './components/SubmissionConfirmation';


const App = () => {
  const [ isLoading, setIsLoading ] = useState( '');
  const [ user, setUser ] = useState( null );
  const [ preferences, setPreferences ] = useState( null );

  return (
    <>
      { Boolean( isLoading )
        ? <Dimmer active page inverted>
            <Loader />
            <p style={{ color: '#333', marginTop: '100px' }}>{ isLoading }</p>
          </Dimmer>
        : null }
      <AppHeader title={ preferences ? 'Thank You!' : 'FERPA Opt-Out Form' } />
      <div className="container">
        { user
          ? preferences
            ? <SubmissionConfirmation preferences={ preferences } />
            : <OptOutForm user={ user } setPreferences={ setPreferences } setIsLoading={ setIsLoading } />
          : <SignInForm setUser={ setUser } setIsLoading={ setIsLoading } />
        }
      </div>
    </>
  );
}

export default App;
