import React from 'react';

const AppHeader = ({ title }) => {
	return (
		<div
			id="app-header"
			style={{ backgroundColor: '#1b1464', padding: '0.75rem 0', textTransform: 'uppercase' }}
		>
			<h3
				style={{
					margin: 0,
					color: '#fff',
					fontSize: '1.6rem',
					fontWeight: 400,
					letterSpacing: '0.05rem',
					textAlign: 'center'
				}}
			>
				{ title }
			</h3>
		</div>
	);
};

export default AppHeader;