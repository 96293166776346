import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from '../hooks/useForm';
import { settings } from '../config.js';

const SignInForm = ({ setUser, setIsLoading }) => {

	const { formData, updateField } = useForm({
		email: ''
	});

	const [ validationError, setValidationError ] = useState( false );
	const [ signInError, setSignInError ] = useState( false );

	const handleSignIn = async () => {
		if ( !formData.email ) {
			setValidationError( true );
			return;
		}
		setIsLoading( 'Locating your C2C application...' );
		try {
			const response = await axios({
				method: 'get',
				baseURL: settings.apiUrl,
				url: '/validate',
				headers: {
					api_key: 'TEtBUElBZG1pbjomWGhoWiQqQGhnNFEhNyM='
				},
				params: {
					email: formData.email
				}
			});

			if ( response.data.id ) {
				setUser({
					id: response.data.id,
					email: response.data.email
				});
			} else {
				setSignInError( true );
			};
			setIsLoading( '' );
		} catch( err ) {
			console.log( err );
			setSignInError( true );
			setIsLoading( '' );
		}
	}

	return (
		<>
			<div className="form-header" style={{ margin: '2rem 0' }}>
				<p>In order to process your FERPA opt-out election for student directory information associated with your participation in the C2C Program, please enter the email address used when submitting your C2C application.</p>
			</div>

			<Form
				style={{ maxWidth: '500px', padding: '1rem 0', boxSizing: 'border-box' }}
			>
				<Form.Input
					required
					name="email"
					label="Email"
					value={ formData.email }
					onChange={ updateField }
				/>
			</Form>

			{ validationError
				? <p style={{ color: 'red' }}>Please complete all required (*) fields</p>
				: null
			}
			{ signInError
				? <p style={{ color: 'red' }}>Sorry, we are unable to locate an application submitted using that email address. Please try again, or <a style={{ color: 'inherit', textDecoration: 'underline' }} href="https://msc2c.org/contact-us">contact us</a> if you have any issues accessing the opt-out form.</p>
				: null
			}

			<Button
				className="btn"
				style={{ boxShadow: '0', marginTop: '2rem' }}
				size="huge"
				content="Submit"
				onClick={ handleSignIn }
			/>
			<Button
				className="btn"
				style={{ boxShadow: '0', marginTop: '2rem' }}
				size="huge"
				content="Cancel"
				onClick={ () => window.location.replace( 'https://msc2c.org/privacy-policy' ) }
			/>
		</>
	);
};

export default SignInForm;