import React from 'react';

const SubmissionConfirmation = ({ preferences }) => {

	const showPreferences = () => {
		const arr = [];
		for ( let preference of preferences.split( ', ' ) ) {
			switch( preference ) {
				case '1':
					arr.push( 'Name' );
					break;
				case '2':
					arr.push( 'Permanent or home address' );
					break;
				case '3':
					arr.push( 'Telephone numbers' );
					break;
				case '4':
					arr.push( 'E-mail address' );
					break;
				case '5':
					arr.push( 'Date of birth' );
					break;
				case '6':
					arr.push( 'Dates of attendance' );
					break;
				case '7':
					arr.push( 'Most recent institution attended' );
					break;
				case '8':
					arr.push( 'Enrollment Status' );
					break;
				case '9':
					arr.push( 'Class standing' );
					break;
				case '10':
					arr.push( 'Degree(s) received' );
					break;
				default:
					break;
			}
		}
		return arr.map( item => <li>{ item }</li> );
	}

	return (
		<div style={{ marginTop: '2rem' }}>
			<p>Your opt-out request to withhold the following personally identifiable information, identified as ‘Directory Information’ under FERPA, was received. <br /> The information you selected will not be released to third parties unless the C2C program is required by law, or permitted under FERPA, to release such information.</p>
			<ul>
				{ showPreferences() }
			</ul>
		</div>
	);
};

export default SubmissionConfirmation;